<template>
    <div class="newsletter-body container">
        <template v-for="(el, key) in json.blocks" :key="key">
            <div v-if="el" class="contenedor-texto">
                <!-- Paragraph -->
                <div v-if="el.type == 'paragraph'" class="paragraph">
                    <p v-html="el.data.text"></p>
                </div>

                <!-- Image -->
                <template v-else-if="el.type == 'image'">
                    <div class="div-image">
                        <img :src="el.data.file.url" class="img-fluid" />
                        <span v-html="el.data.caption" class="pie"></span>
                    </div>
                </template>

                <!-- Header -->
                <h4 :nivel="el.data.level" v-else-if="el.type == 'header'" class="destacado" v-html="el.data.text"></h4>

                <!-- Embed -->
                <div class="container-video" v-if="el.type == 'embed'" :data-service="el.data.service">
                    <iframe :src="el.data.embed"></iframe>

                </div>
                <!-- File -->
                <div class="file" v-else-if="el.type == 'attaches'">
                    <a download :href="el.data.file.url" class="btn-blue-border" rel="noopener" target="_blank">
                        Descargar {{ el.data.title }}</a>
                </div>

                <!-- Slider -->
                <agile :options="myOptions" ref="carousel" v-else-if="el.type == 'gallery'">
                    <div class="slide" v-for="(img, key) in el.data.images" :key="key">
                        <div class="img-content" :style="`background-image:url(` + img.file.url + `)`" :title="img.caption"></div>
                        <!-- <img class="img-fluid" :src="img.file.url" :alt="img.caption" /> -->
                        <span>{{ img.caption }}</span>
                    </div>
                </agile>

                <!-- Prety Link -->
                <div class="preaty-link row-center" v-if="el.type == 'linkTool'">
                    <div class="link w-100">
                        <a :href="el.data.link" rel="noopener" :target="[el.data.blank == true ? '_blank' : '_self']">
                            <strong>{{ el.data.meta.title }}</strong>
                            <!-- <span v-if="el.data.meta.description"> ({{ el.data.meta.description }})</span> -->
                        </a>
                    </div>
                </div>

                <!-- List -->
                <section v-else-if="el.type == 'list' || el.type == 'nestedList'">
                    <template v-if="el.data.style == 'ordered'">
                        <ol>
                            <listComponent v-for="(item, index) in Object.assign({}, el.data.items)" :key="index" :item="item" :listStyle="el.data.style" />
                        </ol>
                    </template>
                    <template v-else>
                        <ul>
                            <listComponent v-for="(item, index) in Object.assign({}, el.data.items)" :key="index" :item="item" :listStyle="el.data.style" />
                        </ul>
                    </template>
                </section>

                <!-- Boton -->


                <div class="button" v-else-if="el.type == 'button'">
                    <a target="_blank" :href="el.data.link" class="btn-blue-border">{{
                        el.data.text
                        }}</a>
                </div>

                <!-- Tabla -->
                <div class="table-container row-center" v-else-if="el.type == 'table'">
                    <table>
                        <tr v-for="(td, tdkey) in el.data.content" :key="tdkey">
                            <td :key="ekey" v-for="(e, ekey) in td" v-html="e"></td>
                        </tr>
                    </table>
                </div>

                <!-- Columnas -->
                <div class="grid-2 " v-else-if="el.type == 'columns'">
                    <div class="grid" v-for="(el, subkey) in el.data.itemContent" :key="subkey">
                        <editorjs :object="el"></editorjs>
                    </div>
                </div>

                <!-- Raw -->
                <div v-else class="raw">
                    <div v-html="el.data.html"></div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { VueAgile } from "vue-agile";
    import listComponent from "@/components/list-component.vue";
    export default {
        name: "editorjs",
        props: ["object"],
        computed: {
            json() {
                if (typeof this.object === "string" || this.object instanceof String) {
                    return JSON.parse(this.object);
                }
                return this.object;
            },
        },
        data: () => ({
            myOptions: {
                responsive: [
                    {
                        breakpoint: 900,
                        settings: {
                            navButtons: false,
                            slidesToShow: 3,
                            dots: true,
                        },
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            navButtons: false,
                            slidesToShow: 2,
                            dots: true,
                        },
                    },
                    {
                        breakpoint: 0,
                        settings: {
                            navButtons: false,
                            slidesToShow: 1,
                            dots: true,
                        },
                    },
                ],
            },
        }),

        components: {
            listComponent,
            agile: VueAgile,
        },
        filters: {},
    };
</script>
<style scoped lang="scss">
    @import "../styles/general.scss";

    ol {
        counter-reset: item;
    }

    ol li {
        display: block;
    }

    .button {
        margin-bottom: 20px;
    }

    ol li:before {
        content: counters(item, ".") " ";
        counter-increment: item;
    }

    h4 {
        margin-bottom: 15px;
    }

    .slide {
        padding: 10px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }

    .slide .img-content {
        width: 100%;
        height: 200px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 20px;
    }

    .slide img {
        width: 100%;
    }

    .agile__actions {
        position: absolute;
        bottom: 20px;
        width: 100%;

        button {
            background: white;
            border: 2px solid $blueLight;
            border-radius: 100%;
            margin: 0 5px;
            width: 12px;
            height: 12px;
            padding: 0;
        }
    }

    .agile__dot--current button {
        background: $blueLight;
    }

    .destacado {
        font-size: 22px;
        line-height: 1.2em;
    }
</style>